import { Component } from '@angular/core';

import { AppConfig } from './app.config';
import { CobrandService } from './cobrand/cobrand.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers:[CobrandService]
})
export class AppComponent {
  title = 'SmartMove Marketing Site';

  constructor(
    private readonly appConfig: AppConfig) {
  }

  ngOnInit(): void {
    console.log('Using API=' + this.appConfig.api_url);
  }

}
