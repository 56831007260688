﻿import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';

import { AppConfig } from '../app.config';
import { CoBrand } from 'app/cobrand/cobrand';

import { CobrandService } from './cobrand.service';

@Component({
  selector: 'app-cobrand',
  templateUrl: './cobrand.component.html',
  styleUrls: ['./cobrand.component.css']
})

export class CobrandComponent implements OnInit {
  errorMessage: any;
  pageTitle;
  cobrand = new CoBrand();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private readonly appConfig: AppConfig,
    private _cobrandService: CobrandService) {
    this.pageTitle = this.getCobrandName();
  }

  ngOnInit(): void {
    this._cobrandService.getCobrand(this.getCobrandName())
      .subscribe(c => this.cobrand = c,
        error => this.errorMessage = <any>error);
  }

  gotoCreateAccount(): void {
    this.document.location.href = this.appConfig.createAccount_url + '?cobrandid=' + this.cobrand.coBrandId;
  }
  gotoLogin(): void {
    this.document.location.href = this.appConfig.login_url + '?cobrandid=' + this.cobrand.coBrandId;
  }

  getCobrandName(): string {
    const pathname = document.location.pathname;
    if (pathname === '/') {
      return '1';
    }
    return pathname.slice(1, pathname.length);
  }
}
