<div class="container">
  <div class=row>
    <div class="col-md-12 list-group">
      <h3 class="list-group-item active">Cobrand Info</h3>
      <div class="list-group-item">
        <div id="divCoBrandId" class="list-group-item-text" style="padding-left:20px">
          Cobrand Id: {{cobrand.coBrandId}}
        </div>

        <div id="divPageTitle" class="list-group-item-text" style="padding-left:20px">
          Cobrand: {{pageTitle}}
        </div>
        <div id="divCobrandFound" class="list-group-item-text" style="padding-left:20px">
          Cobrand Found: {{cobrand.isCobrandFound}}
        </div>
        <div id="divRetail" class="list-group-item-text" style="padding-left:20px">
          Retail: {{cobrand.isRetail}}
        </div>
        <div id="divUrl" class="list-group-item-text" style="padding-left:20px">
          Url: {{cobrand.url}}
        </div>
      </div>
    </div>
  </div>
  <div class=row>
    <div class="col-md-12 list-group">
      <h3 class="list-group-item active">Bundles</h3>
      <div class="col-md-12 list-group-item">
        <div class="col-md-4" *ngFor="let bundle of cobrand.bundles | reverse">
          <h3 id="bundleName" class="list-group-item bundle">{{bundle.name}}</h3>
          <div class="list-group-item">
            <div id="divBundleId" class="list-group-item-text" style="padding-left:20px">
              Bundle Id: {{bundle.bundleId}}
            </div>
            <div id="divDescription" class="list-group-item-text" style="padding-left:20px">
              Description: {{bundle.description}}
            </div>
            <div id="divPrice" class="list-group-item-text" style="padding-left:20px">
              Price: {{bundle.price}}
            </div>
            <div id="divHidden" class="list-group-item-text" style="padding-left:20px">
              Hidden: {{bundle.isHidden}}
            </div>
          </div>
          <div id="divServices" class="list-group-item active">
            <h4>Services</h4>
          </div>
          <div class="list-group-item" *ngFor="let service of bundle.services">
            <div id="divServiceId" class="list-group-item-text" style="padding-left:20px">
              Service Id: {{service.serviceId}}
            </div>
            <div id="divServiceName" class="list-group-item-text" style="padding-left:20px">
              Name: {{service.name}}
            </div>
            <div id="divServiceDescription" class="list-group-item-text" style="padding-left:20px">
              Description: {{service.description}}
            </div>
            <div id="divServiceCode" class="list-group-item-text" style="padding-left:20px">
              Code: {{service.code}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" align=center>      
      <button id="btnLogin" class="button" (click)="gotoLogin()">Login</button>
      <button id="btnCreateAccount" class="button" (click)="gotoCreateAccount()">Create Account</button>      
    </div>
  </div>

</div>