import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig, loadConfigurationSettings } from './app.config';
import { CobrandComponent } from './cobrand/cobrand.component';
import { ReversePipe } from './cobrand/reverse.pipe';

@NgModule({
  declarations: [
    AppComponent,
    CobrandComponent,
    ReversePipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule
  ],
  providers: [
    HttpClient,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigurationSettings,
      deps: [AppConfig],
      multi: true
    }
  ],
  exports: [
    ReversePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
