import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';

import { AppConfig } from '../app.config';
import { CoBrand } from './cobrand';

@Injectable()
export class CobrandService {
    public cobrandUrl: string;

    constructor(
        private httpClient: HttpClient,
        public readonly appConfig: AppConfig) {
            this.cobrandUrl = this.appConfig.api_url + '/CoBrands/';
        }

    getCobrand(hostName: string): Observable<CoBrand> {
        console.log('');
        return this.httpClient.get(this.cobrandUrl + hostName)
            .map(response => <CoBrand>response)
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        console.error(error);
        return Observable.throw(error.json().error || 'Server Error');
    }
}