import { NgModule, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../environments/environment';


export function loadConfigurationSettings(appConfig: AppConfig) {
    return () => appConfig.load();
}


@Injectable()
export class AppConfig {
    private configurationFilename = environment.configFileName;
    private config: Object = null;

    constructor(private httpClient: HttpClient) { }

    get api_url(): string {
        return this.config['api_url'];
    }

    get createAccount_url(): string {
        return this.config['createAccount_url'];
    }

    get login_url(): string {
        return this.config['login_url'];
    }


    // load the "config.json" to get all environment variables
    public load() {
        return new Promise((resolve, reject) => {
            this.httpClient
                .get(this.configurationFilename)
                .subscribe(
                    (responseData: any) => {
                        this.config = responseData;
                        resolve(true);
                    },
                    (error: HttpErrorResponse) => {
                        console.error('Error reading configuration file');
                        resolve(error);
                    }
                );
        });
    }

}
